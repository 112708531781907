import emailjs from '@emailjs/browser';

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

const MAX_EMAIL_SIZE = 1500000; // 1.5MB limit for most email services
const MAX_PREVIEW_LINES = 100;
const MAX_CONTENT_LENGTH = 100000;

const formatFileSize = (bytes) => {
  if (bytes < 1024) return bytes + ' B';
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
  else return (bytes / 1048576).toFixed(2) + ' MB';
};

const truncateContent = (content, maxLines = MAX_PREVIEW_LINES) => {
  if (!content) return 'No content provided';
  
  // Remove empty lines and trim content
  const lines = content.split('\n')
    .filter(line => line.trim())
    .map(line => line.trim());
    
  // Get total size
  const totalSize = new Blob([content]).size;
  const sizeInfo = formatFileSize(totalSize);
  
  // Always show at least first 5 lines of content, regardless of size
  const previewLines = lines.slice(0, 5)
    .map(line => {
      // Truncate each line if too long
      return line.length > 200 ? line.substring(0, 200) + '...' : line;
    })
    .join('\n');
  
  return `File size: ${sizeInfo}\nTotal lines: ${lines.length}\n\nPreview (first 5 lines):\n${previewLines}\n...\n(Remaining lines truncated)`;
};

const createEmailContent = (details) => {
  const basicInfo = `
User Email: ${details.userEmail}
Model Name: ${details.modelName}
AI Model Persona: ${details.aiPersona}
Base Model: ${details.baseModel}
Learning Rate: ${details.learningRate}
Epochs: ${details.epochs}
Train-Test Split: ${details.trainTestSplit}%

Files Information:
- Training File: ${details.trainingFile.name} (${formatFileSize(new Blob([details.trainingFile.content]).size)})
- Validation File: ${details.validationFile?.name || 'Not provided'} ${
    details.validationFile ? 
    `(${formatFileSize(new Blob([details.validationFile.content]).size)})` : 
    ''
  }
`;

  // Parse and format JSONL content for preview
  const formatJSONLPreview = (content) => {
    try {
      // Get first 10 lines instead of 3
      const firstFewLines = content.split('\n')
        .filter(line => line.trim())
        .slice(0, 10)
        .map(line => {
          const parsed = JSON.parse(line);
          // Only show relevant parts of the JSON to save space
          if (parsed.messages && parsed.messages[0]) {
            const msg = parsed.messages[0];
            return JSON.stringify({
              role: msg.role,
              content: msg.content.substring(0, 500) // Show first 500 chars of content
            }, null, 2);
          }
          return JSON.stringify(parsed, null, 2);
        })
        .join('\n\n');
      return firstFewLines;
    } catch (e) {
      console.error('JSON parsing failed:', e);
      return content.substring(0, 1000); // Fallback to first 1000 chars if parsing fails
    }
  };

  const trainingPreview = `\nTraining File Preview:\n${truncateContent(formatJSONLPreview(details.trainingFile.content))}`;
  const validationPreview = details.validationFile ? 
    `\nValidation File Preview:\n${truncateContent(formatJSONLPreview(details.validationFile.content))}` : 
    '';

  const fullContent = basicInfo + trainingPreview + validationPreview;
  if (fullContent.length > MAX_EMAIL_SIZE) {
    return basicInfo + '\n\nFile contents too large to include in email notification.';
  }

  return fullContent;
};

export const sendNotificationEmail = async (action, details) => {
  if (!process.env.REACT_APP_SERVICE_ID || !process.env.REACT_APP_TEMPLATE_ID) {
    throw new Error('Email service configuration is missing');
  }

  if (!details.userEmail) {
    throw new Error('User email is required');
  }

  try {
    const emailContent = createEmailContent(details);

    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      {
        to_name: details.userEmail,
        message: emailContent,
        subject: action,
        timestamp: new Date().toLocaleString()
      }
    );

    if (response.status !== 200) {
      throw new Error(`Email service returned status ${response.status}`);
    }

    return true;
  } catch (error) {
    console.error('Email notification failed:', error);
    const errorMessage = error.text || error.message || 'Failed to send email notification';
    throw new Error(errorMessage);
  }
};