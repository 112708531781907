import { v4 as uuidv4 } from 'uuid';

// Initialize API keys in localStorage if they don't exist
const initializeApiKeys = () => {
  const storedApiKeys = localStorage.getItem('apiKeys');
  if (!storedApiKeys) {
    // Generate new API keys for all users
    const apiKeys = {
      'fintelite_user@email.com': `sk-tagflow-${uuidv4()}`,
      'test_user@email.com': `sk-tagflow-${uuidv4()}`,
      'fajarluhung@sribuu.id': `sk-tagflow-${uuidv4()}`,
      'arief.wijaya@fintelite.ai': `sk-tagflow-${uuidv4()}`,
      'martin@fintelite.ai': `sk-tagflow-${uuidv4()}`,
      'nadia@sribuu.id': `sk-tagflow-${uuidv4()}`
    };
    localStorage.setItem('apiKeys', JSON.stringify(apiKeys));
    return apiKeys;
  }

  const existingApiKeys = JSON.parse(storedApiKeys);
  
  // Check if any users are missing API keys and generate them
  const allUsers = [
    'fintelite_user@email.com',
    'test_user@email.com',
    'fajarluhung@sribuu.id',
    'arief.wijaya@fintelite.ai',
    'martin@fintelite.ai',
    'nadia@sribuu.id'
  ];

  let hasNewKeys = false;
  allUsers.forEach(user => {
    if (!existingApiKeys[user]) {
      existingApiKeys[user] = `sk-tagflow-${uuidv4()}`;
      hasNewKeys = true;
    }
  });

  if (hasNewKeys) {
    localStorage.setItem('apiKeys', JSON.stringify(existingApiKeys));
  }

  return existingApiKeys;
};

const apiKeys = initializeApiKeys();

const VALID_CREDENTIALS = [
  {
    username: 'fintelite_user@email.com',
    password: 'fintelite_password',
    apiKey: apiKeys['fintelite_user@email.com']
  },
  {
    username: 'test_user@email.com',
    password: 'test_password',
    apiKey: apiKeys['test_user@email.com']
  },
  {
    username: 'fajarluhung@sribuu.id',
    password: 'fintelite_password',
    apiKey: apiKeys['fajarluhung@sribuu.id']
  },
  {
    username: 'arief.wijaya@fintelite.ai',
    password: 'fintelite_password',
    apiKey: apiKeys['arief.wijaya@fintelite.ai']
  },
  {
    username: 'martin@fintelite.ai',
    password: 'fintelite_password',
    apiKey: apiKeys['martin@fintelite.ai']
  },
  {
    username: 'nadia@sribuu.id',
    password: 'fintelite_password',
    apiKey: apiKeys['nadia@sribuu.id']
  }
];

export const authenticate = (username, password) => {
  return VALID_CREDENTIALS.some(
    cred => cred.username === username && cred.password === password
  );
};

export const setAuthToken = (username) => {
  const user = VALID_CREDENTIALS.find(cred => cred.username === username);
  if (!user) {
    console.error('User not found:', username);
    return;
  }
  
  localStorage.setItem('isAuthenticated', 'true');
  localStorage.setItem('currentUser', username);
  localStorage.setItem('apiKey', user.apiKey);
  console.log('API Key set:', user.apiKey); // Debug log
};

export const clearAuthToken = () => {
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('apiKey');
};

export const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

export const getCurrentUser = () => {
  return localStorage.getItem('currentUser');
};

export const getCurrentApiKey = () => {
  const apiKey = localStorage.getItem('apiKey');
  if (!apiKey) {
    const currentUser = getCurrentUser();
    if (currentUser) {
      const user = VALID_CREDENTIALS.find(cred => cred.username === currentUser);
      if (user) {
        localStorage.setItem('apiKey', user.apiKey);
        return user.apiKey;
      }
    }
  }
  return apiKey;
};

