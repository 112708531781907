import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';

const ModelDetails = () => {
  const { modelId } = useParams();
  const { models } = useModels();
  const [activeTab, setActiveTab] = useState('overview');
  
  const model = models.find(m => m.id === modelId) || {
    id: modelId,
    name: 'Not Found',
    status: 'ERROR',
    provider: 'N/A',
    baseModel: 'N/A',
    persona: 'Model not found',
    dataset: { trainingSize: 0, testSize: 0 },
    trainingConfig: { learningRate: 0, numEpochs: 0, batchSize: 'auto' },
    createdAt: 'N/A',
    apiEndpoint: 'N/A'
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // Add toast notification here
    alert('Copied to clipboard!'); // Replace with proper toast
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>{model.name}</h1>
        <div className={`model-status ${model.status.toLowerCase()}`}>
          {model.status}
        </div>
      </div>

      <div className="model-details-tabs">
        <button 
          className={activeTab === 'overview' ? 'active' : ''} 
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={activeTab === 'dataset' ? 'active' : ''} 
          onClick={() => setActiveTab('dataset')}
        >
          Dataset
        </button>
        <button 
          className={activeTab === 'api' ? 'active' : ''} 
          onClick={() => setActiveTab('api')}
        >
          API Integration
        </button>
        <button 
          className={activeTab === 'weights' ? 'active' : ''} 
          onClick={() => setActiveTab('weights')}
        >
          Export Weights
        </button>
      </div>

      {activeTab === 'overview' && (
        <div className="details-section">
          <h2>Model Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <label>Provider</label>
              <span>{model.provider}</span>
            </div>
            <div className="info-item">
              <label>Base Model</label>
              <span>{model.baseModel}</span>
            </div>
            <div className="info-item">
              <label>AI Persona</label>
              <span>{model.persona}</span>
            </div>
            <div className="info-item">
              <label>Learning Rate</label>
              <span>{model.trainingConfig.learningRate}</span>
            </div>
            <div className="info-item">
              <label>Training Epochs</label>
              <span>{model.trainingConfig.numEpochs}</span>
            </div>
            <div className="info-item">
              <label>Training Set Size</label>
              <span>{model.dataset.trainingSize}</span>
            </div>
            <div className="info-item">
              <label>Test Set Size</label>
              <span>{model.dataset.testSize}</span>
            </div>
            <div className="info-item">
              <label>Created At</label>
              <span>{model.createdAt}</span>
            </div>
          </div>
        </div>
      )}

{activeTab === 'api' && (
  <div className="details-section">
    <h2>API Integration</h2>
    <div className="code-block">
      <pre>
        {`curl -X POST \\
  http://54.84.155.19:5001/tagflow-ai/api/chat \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"input": "What is your question? Write it here!", "model": "${model.name}"}'`}
      </pre>
      <button onClick={() => copyToClipboard(`curl -X POST \\
  http://54.84.155.19:5001/tagflow-ai/api/chat \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"input": "What is your question? Write it here!", "model": "${model.name}"}'`)}>
        Copy
      </button>
    </div>
  </div>
)}

      {activeTab === 'weights' && (
        <div className="details-section">
          <h2>Export Weights</h2>
          <div className="weights-grid">
            <div className="weight-format">
              <h3>LoRA: BF16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
            <div className="weight-format">
              <h3>LoRA: FP16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
            <div className="weight-format">
              <h3>Merged: BF16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'dataset' && (
        <div className="details-section">
          <h2>Dataset Content</h2>
          <div className="dataset-viewer">
            <div className="dataset-header">
              <span>JSONL Dataset</span>
              <button 
                className="copy-btn"
                onClick={() => copyToClipboard(model.dataset.content)}
              >
                Copy Dataset
              </button>
            </div>
            <pre className="dataset-content">
              {model.dataset.content || 'No dataset content available'}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelDetails;