import React, { useState } from 'react';
import { useModels } from '../context/ModelsContext';
import './Login.css';

function AdminUpload() {
  const { addModel } = useModels();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [uploadData, setUploadData] = useState({
    targetUser: '',
    modelName: '',
    provider: 'tagflow',
    baseModel: 'Mistral 7B',
    aiPersona: '',
    learningRate: 0.0001,
    trainingEpochs: 10,
    trainingSetSize: 0,
    testSetSize: 0,
    file: null,
    message: ''
  });

  const handleAdminLogin = (e) => {
    e.preventDefault();
    if (credentials.username === process.env.REACT_APP_ADMIN_USER && 
        credentials.password === process.env.REACT_APP_ADMIN_PASS) {
      setIsAuthenticated(true);
    } else {
      alert('Invalid admin credentials');
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!uploadData.targetUser || !uploadData.file || !uploadData.modelName) {
      alert('Please fill in all required fields');
      return;
    }

    setIsSubmitting(true);
    try {
      // Read the file content
      const fileContent = await uploadData.file.text();
      
      const newModel = {
        id: `model-${Date.now()}`,
        name: uploadData.modelName,
        status: 'DEPLOYED',
        provider: uploadData.provider,
        baseModel: uploadData.baseModel,
        persona: uploadData.aiPersona,
        userEmail: uploadData.targetUser.toLowerCase(),
        dataset: {
          trainingSize: uploadData.trainingSetSize,
          testSize: uploadData.testSetSize,
          content: fileContent
        },
        trainingConfig: {
          learningRate: uploadData.learningRate,
          numEpochs: uploadData.trainingEpochs,
          batchSize: 'auto'
        },
        createdAt: new Date().toLocaleString(),
        apiEndpoint: 'https://api.tagflow.ai/v1/chat/completions'
      };

      addModel(newModel);

      // Reset form including the new modelName field
      setUploadData({
        targetUser: '',
        modelName: '',
        provider: 'tagflow',
        baseModel: 'Mistral 7B',
        aiPersona: '',
        learningRate: 0.0001,
        trainingEpochs: 10,
        trainingSetSize: 0,
        testSetSize: 0,
        file: null,
        message: ''
      });

      alert('Model uploaded successfully!');
    } catch (error) {
      console.error('Upload error:', error);
      alert('Upload failed. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <div className="login-box">
          <h2>Admin Login</h2>
          <form onSubmit={handleAdminLogin}>
            <div className="input-group">
              <label>Username</label>
              <input
                type="text"
                className="text-input"
                value={credentials.username}
                onChange={(e) => setCredentials({...credentials, username: e.target.value})}
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                className="text-input"
                value={credentials.password}
                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
              />
            </div>
            <button type="submit" className="login-btn">Login</button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Upload Model Details</h2>
        <form onSubmit={handleUpload}>
          <div className="input-group">
            <label>Target User Email</label>
            <input
              type="email"
              className="text-input"
              value={uploadData.targetUser}
              onChange={(e) => setUploadData({...uploadData, targetUser: e.target.value})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Model Name</label>
            <input
              type="text"
              className="text-input"
              value={uploadData.modelName}
              onChange={(e) => setUploadData({...uploadData, modelName: e.target.value})}
              disabled={isSubmitting}
              required
            />
          </div>
          <div className="input-group">
            <label>Provider</label>
            <input
              type="text"
              className="text-input"
              value={uploadData.provider}
              onChange={(e) => setUploadData({...uploadData, provider: e.target.value})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Base Model</label>
            <input
              type="text"
              className="text-input"
              value={uploadData.baseModel}
              onChange={(e) => setUploadData({...uploadData, baseModel: e.target.value})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>AI Persona</label>
            <textarea
              className="text-input"
              value={uploadData.aiPersona}
              onChange={(e) => setUploadData({...uploadData, aiPersona: e.target.value})}
              disabled={isSubmitting}
              placeholder="Enter the model's instruction prompt..."
            />
          </div>
          <div className="input-group">
            <label>Learning Rate</label>
            <input
              type="number"
              step="0.0001"
              className="text-input"
              value={uploadData.learningRate}
              onChange={(e) => setUploadData({...uploadData, learningRate: parseFloat(e.target.value)})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Training Epochs</label>
            <input
              type="number"
              className="text-input"
              value={uploadData.trainingEpochs}
              onChange={(e) => setUploadData({...uploadData, trainingEpochs: parseInt(e.target.value)})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Training Set Size</label>
            <input
              type="number"
              className="text-input"
              value={uploadData.trainingSetSize}
              onChange={(e) => setUploadData({...uploadData, trainingSetSize: parseInt(e.target.value)})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Test Set Size</label>
            <input
              type="number"
              className="text-input"
              value={uploadData.testSetSize}
              onChange={(e) => setUploadData({...uploadData, testSetSize: parseInt(e.target.value)})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>File</label>
            <input
              type="file"
              onChange={(e) => setUploadData({...uploadData, file: e.target.files[0]})}
              disabled={isSubmitting}
            />
          </div>
          <div className="input-group">
            <label>Message (optional)</label>
            <textarea
              className="text-input"
              value={uploadData.message}
              onChange={(e) => setUploadData({...uploadData, message: e.target.value})}
              disabled={isSubmitting}
            />
          </div>
          <button type="submit" className="login-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Uploading...' : 'Upload'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminUpload;