import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCurrentUser } from '../auth';

const ModelsContext = createContext();

export const ModelsProvider = ({ children }) => {
  const [models, setModels] = useState(() => {
    const savedModels = localStorage.getItem('models');
    return savedModels ? JSON.parse(savedModels) : [];
  });
  
  const [activities, setActivities] = useState(() => {
    const savedActivities = localStorage.getItem('activities');
    return savedActivities ? JSON.parse(savedActivities) : [];
  });

  useEffect(() => {
    localStorage.setItem('models', JSON.stringify(models));
    localStorage.setItem('activities', JSON.stringify(activities));
  }, [models, activities]);

  const currentUser = getCurrentUser();

  // Add null check before filtering
  const userModels = currentUser 
    ? models.filter(model => 
        model.userEmail && model.userEmail.toLowerCase() === currentUser.toLowerCase()
      )
    : [];

  const userActivities = currentUser
    ? activities.filter(activity => 
        activity.userEmail && activity.userEmail.toLowerCase() === currentUser.toLowerCase()
      )
    : [];

  const addModel = (model) => {
    setModels(prevModels => [...prevModels, model]);
    
    const newActivity = {
      time: new Date().toISOString(),
      text: `Model '${model.name}' created and deployed`,
      type: 'model_created',
      userEmail: model.userEmail
    };
    
    setActivities(prev => [newActivity, ...prev].slice(0, 10));
  };

  return (
    <ModelsContext.Provider value={{ 
      models: userModels,
      activities: userActivities,
      addModel,
      activeModelsCount: userModels.length 
    }}>
      {children}
    </ModelsContext.Provider>
  );
};

export const useModels = () => {
  const context = useContext(ModelsContext);
  if (!context) {
    throw new Error('useModels must be used within a ModelsProvider');
  }
  return context;
};
