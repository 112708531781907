import React from 'react';
import './DashboardComponents.css';

const Datasets = () => {
  const datasets = [
    {
      fileName: 'legal_contracts.jsonl',
      fileId: '136a9dd7-ba6c-4081-a14f-1dbcf42f8750',
      type: 'instruct',
      source: 'Uploaded',
      size: '2.3 MB (1,234 lines)',
      creationDate: '12/15/2023, 3:45:21 PM'
    },
    {
      fileName: 'medical_records.jsonl',
      fileId: '892b1cc5-dd34-4567-b123-9abcdef12345',
      type: 'instruct',
      source: 'Uploaded',
      size: '1.8 MB (856 lines)',
      creationDate: '12/14/2023, 2:30:15 PM'
    }
  ];

  const handleUpload = () => {
    // Implement file upload logic
    console.log('Upload new dataset');
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <div className="header-with-button">
          <div className="header-text">
            <h1>Datasets</h1>
            <p>Manage your training datasets</p>
          </div>
          <button onClick={handleUpload} className="upload-btn">
            Upload new dataset
          </button>
        </div>
      </div>
      <div className="datasets-table">
        <table>
          <thead>
            <tr>
              <th>File name</th>
              <th>File ID</th>
              <th>Type</th>
              <th>Source</th>
              <th>Size</th>
              <th>Creation date</th>
            </tr>
          </thead>
          <tbody>
            {datasets.map((dataset, index) => (
              <tr key={dataset.fileId}>
                <td>{dataset.fileName}</td>
                <td>{dataset.fileId}</td>
                <td>{dataset.type}</td>
                <td>{dataset.source}</td>
                <td>{dataset.size}</td>
                <td>{dataset.creationDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Datasets;
