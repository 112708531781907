import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';
import { getCurrentUser } from '../../auth';

const Models = () => {
  const navigate = useNavigate();
  const { models } = useModels();
  const currentUser = getCurrentUser();
  
  // Filter models for current user
  const userModels = models.filter(model => 
    model.userEmail && 
    model.userEmail.toLowerCase() === currentUser.toLowerCase()
  );
  
  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Models</h1>
        <p>Manage your fine-tuned models</p>
      </div>
      <div className="models-grid">
        {userModels.map((model) => (
          <div 
            key={model.id}
            className="model-card"
            onClick={() => navigate(`/dashboard/models/${model.id}`)}
            style={{ cursor: 'pointer' }}
          >
            <div className="model-header">
              <h3>{model.name}</h3>
              <span className={`model-status ${model.status.toLowerCase()}`}>
                {model.status}
              </span>
            </div>
            <p className="model-description">{model.persona.substring(0, 100)}...</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Models;
