import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';

const Overview = () => {
  const navigate = useNavigate();
  const { activeModelsCount, activities } = useModels();

  const handleFineTuneClick = () => {
    navigate('/dashboard/fine-tune');
  };

  const handleModelStatsClick = () => {
    navigate('/dashboard/models');
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInHours < 24) return `${diffInHours}h ago`;
    if (diffInDays === 1) return 'Yesterday';
    
    // For anything older than yesterday, show the date
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Fine Tune</h1>
        <p>Welcome to TagflowAI Fine Tuning Console</p>
      </div>
      <div className="stats-grid">
        <div 
          className="stat-box"
          onClick={handleModelStatsClick}
        >
          <h3>Active Models</h3>
          <p className="stat-number">{activeModelsCount}</p>
        </div>
        <div 
          className="stat-box action-box"
          onClick={handleFineTuneClick}
        >
          <div className="action-content">
            <h3>Fine-tune a model</h3>
            <p>Create and deploy a fine-tuned model from your own data.</p>
          </div>
          <div className="arrow-icon">→</div>
        </div>
      </div>
      <div className="recent-activity">
        <h2>Recent Activity</h2>
        <div className="activity-list">
          {activities.length > 0 ? (
            activities.map((activity, index) => (
              <div key={index} className="activity-item">
                <span className="activity-time">{formatTime(activity.time)}</span>
                <span className="activity-text">{activity.text}</span>
              </div>
            ))
          ) : (
            <div className="activity-item">
              <span className="activity-text">No recent activity</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;