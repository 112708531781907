import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DashboardComponents.css';
import logo from '../../assets/images/logo.png';

const Sidebar = ({ onLogout, currentUser }) => {
  const location = useLocation();
  
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div className="sidebar-logo">
          <img src={logo} alt="TagflowAI Logo" />
          <h2>TagflowAI</h2>
        </div>
      </div>
      <nav className="sidebar-nav">
        <Link 
          to="/dashboard" 
          className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}
        >
          Fine Tune
        </Link>
        <Link 
          to="/dashboard/models" 
          className={`nav-item ${location.pathname === '/dashboard/models' ? 'active' : ''}`}
        >
          Models
        </Link>
        <Link 
          to="/dashboard/api-keys" 
          className={`nav-item ${location.pathname === '/dashboard/api-keys' ? 'active' : ''}`}
        >
          API Keys
        </Link>
      </nav>
      <div className="sidebar-footer">
        <span className="user-email">{currentUser}</span>
        <button onClick={onLogout} className="logout-btn">
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;