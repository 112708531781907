import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { clearAuthToken, getCurrentUser } from '../auth';
import Sidebar from './dashboard/Sidebar';
import Overview from './dashboard/Overview';
import Models from './dashboard/Models';
import Datasets from './dashboard/Datasets';
import ApiKeys from './dashboard/ApiKeys';
import FineTune from './dashboard/FineTune';
import ModelDetails from './dashboard/ModelDetails';
import './Dashboard.css';
import { ModelsProvider } from '../context/ModelsContext';

function Dashboard() {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  const handleLogout = () => {
    clearAuthToken();
    navigate('/login');
  };

  return (
    <ModelsProvider>
      <div className="dashboard-container">
        <Sidebar onLogout={handleLogout} currentUser={currentUser} />
        <main className="dashboard-content">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/models" element={<Models />} />
            <Route path="/datasets" element={<Datasets />} />
            <Route path="/api-keys" element={<ApiKeys />} />
            <Route path="/fine-tune" element={<FineTune />} />
            <Route path="/models/:modelId" element={<ModelDetails />} />
          </Routes>
        </main>
      </div>
    </ModelsProvider>
  );
}

export default Dashboard;

